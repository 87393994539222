require(['SearchFormComponent', 'Carousel', 'imageLazyLoad', 'htmlSectionLazyLoad', 'locationCountsGetter', 'imageSlider'],
  function (SearchFormComponent, Carousel) {
    let form = document.querySelector('#searchFormHeroImage');
    const inputElement = form.querySelector("[data-component-rol='target-input']");
    const validationField = document.querySelector("#heroSearchboxErrorMessage");

    var search = new SearchFormComponent({
      form: document.getElementById("searchFormHeroImage"),
      validationError: document.querySelector("#heroSearchboxErrorMessage"),
      submitButton: document.querySelector("[data-search-submit]"),
      searchTextField: form.querySelector("[data-component-rol='target-input']"),
      shouldAlwaysSubmitForm: true,
      useAutocompleteForSubmit: true
    });

    var testimonialCarousel = new Carousel(document.getElementById("testimonialContainer"), {
      stepSize: 1,
      controls: true,
      visibleItems: 1,
      rightArrow: document.querySelector('[data-rigth]'),
      leftArrow: document.querySelector('[data-left]')
    });

    const buttonSetBetaCookie = document.querySelector('[data-set-beta-cookie]');
    if (buttonSetBetaCookie) {
      buttonSetBetaCookie.onclick = function () {
        const inputText = inputElement ? inputElement.value : '';
        if (inputText.trim().length > 0) {
          const request = new XMLHttpRequest();
          request.open('GET', '/ajax/setbetacookie', true);
          request.onreadystatechange = function () {
            if (request.readyState == request.DONE) { // XMLHttpRequest.DONE == 4
              if (request.status == 200) {
                document.getElementById("searchFormHeroImage").submit();
              }
            }
          };
          request.send();
        } else {
          if (validationField) {
            validationField.style.display = 'block';
          }
        }
      };
    }
  });
define("nhs/views/home/Show", function(){});

