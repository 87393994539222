define('imageLazyLoadConfig',[], function() {
    var _configurationInstance = {
        NoPhotoUrl: "https://nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_180x120.png",
        ImageNotLoadedSelector: "img[data-lazy-image]:not([data-lazy-loaded])",
        LazyLoadedAttributeName: "data-lazy-loaded",
        DefaultIntersectionRootMargin: "200px 0px",
        DefaultIntersectionThreshold: 0.25,
        LowQualityAttribute: "data-lowquality",
        LazyLoadCssClass: "nhs_lazyLoadImage",
        LoadSizeCssClass: "nhs_lazyLoadSize"
    };

    var getConfigurationInstance = function() {
        if (Object.assign) { //Don't want to break in case somehow this gets called by unsupported browsers like IE. getConfigValue can be used for that.
            return Object.assign({}, _configurationInstance);
        }
        return {};
    }

    var getConfigValue = function(configKey) {
        return _configurationInstance[configKey];
    }

    return {
        getConfigurationInstance: getConfigurationInstance,
        getConfigValue: getConfigValue

    }
   
});
