define('imageSlider',[], function() {
    "use strict";

    var sliderComponents = [].slice.call(document.querySelectorAll("[data-component='slider']"));
    var sliderOverridesElement = document.getElementById("imageSliderOverrides");
    var sliderOverrides = null;
    var timeout;

    if (!sliderComponents || !sliderComponents.length) {
        return;
    }

    if (sliderOverridesElement) {
        sliderOverrides = JSON.parse(sliderOverridesElement.innerHTML);
    }

    function changeAttributes(element) {
        var source = element.getAttribute("data-src");
        var sourceSet = element.getAttribute("data-srcset");

        if (source) {
            element.src = source;
        }

        if (sourceSet) {
            element.srcset = sourceSet;
        }
    }

    function displayElement(element) {
        element.removeAttribute("hidden");
    }

    function loadCss(element) {
        var overrides = {}
        var index;

        overrides["data-slider-viewport"] = null;
        overrides["data-slider-direction"] = null;
        overrides["data-slider-duration"] = null;
        overrides["data-slider-unit"] = null;

        if (sliderOverrides && sliderOverrides.elements && sliderOverrides.elements.length) {
            for (index = 0; index < sliderOverrides.elements.length; index += 1) {
                var elementOverride = sliderOverrides.elements[index];
                var properties = sliderOverrides.elements[index].properties;

                if (elementOverride.id !== element.id || !elementOverride.query || !properties) {
                    continue;
                }

                var mediaQuery = window.matchMedia(elementOverride.query);

                if (!mediaQuery.matches) {
                    continue;
                }

                for (var key in properties) {
                    if (properties.hasOwnProperty(key)) {
                        overrides[key] = properties[key];
                    }
                }
            }
        }

        var css = document.createElement("style");
        var animationName = element.id + "slider";
        var animationSteps = "";
        var slidesCount = element.querySelectorAll("[data-slider]").length;
        var step = 100 / slidesCount;
        var viewportStep = (overrides["data-slider-viewport"] || element.getAttribute("data-slider-viewport")) * 1;
        var direction = overrides["data-slider-direction"] || element.getAttribute("data-slider-direction") || "left";
        var duration = overrides["data-slider-duration"] || element.getAttribute("data-slider-duration") || 3;
        var measureUnit = overrides["data-slider-unit"] || element.getAttribute("data-slider-unit") || "vw";
        var initialValue = 0;
        var viewport = initialValue;
        var directionCss;

        if ("left" === direction) {
            directionCss = "-";
        } else {
            directionCss = "";
        }

        for (index = 1; index <= slidesCount; index += 1) {
            var beforeAnimation = index * step - 5;
            var onAnimation = index * step;

            if (index === 1) {
                animationSteps += beforeAnimation + "% { transform: translateX(" + initialValue + "); }";
            } else {
                animationSteps += beforeAnimation + "% { transform: translateX(" + directionCss + viewport + measureUnit + "); } ";
            }

            if (index === slidesCount) {
                animationSteps += "100% { transform: translateX(" + directionCss + viewport + measureUnit + "); }";
                continue;
            }

            viewport += viewportStep;

            if (direction === "left") {
                if (document.getElementById("nhs_Main")) {
                    viewport += 2;
                } else {
                    viewport += 2.5;
                }
            }

            animationSteps += onAnimation + "% { transform: translateX(" + directionCss + viewport + measureUnit + "); }";
        }

        css.type = "text/css";
        css.id = "animationFor" + element.id;
        css.innerHTML = "#" + element.id + " { " +
            "animation: " + animationName + " " + duration + "s linear infinite;" +
            "-moz-animation: " + animationName + " " + duration + "s linear infinite;" +
            "-webkit-animation: " + animationName + " " + duration + "s linear infinite;" +
            "} " +
            "@keyframes " + animationName + "{" +
            "0% { transform: translateX(" + initialValue + "); }" +
            animationSteps +
            "}";
        document.body.appendChild(css);
    }
    function loadImages() {
        var elementsToLoad = [].slice.call(document.querySelectorAll("picture[data-lazyLoad] > source, picture[data-lazyLoad] > img"));
        var elementsHidden = [].slice.call(document.querySelectorAll("picture[data-lazyLoad] > img"));

        elementsToLoad.forEach(changeAttributes);
        elementsHidden.forEach(displayElement);
        sliderComponents.forEach(loadCss);
    }

    if ("complete" === document.readyState) {
        loadImages();
    } else {
        window.addEventListener("load", loadImages, false);
    }

    function resetAnimations() {
        clearTimeout(timeout);

        for (var index = 0; index < sliderComponents.length; index += 1) {
            var currentCss = document.getElementById("animationFor" + sliderComponents[index].id);

            if (currentCss) {
                currentCss.parentElement.removeChild(currentCss);
            }
        }

        timeout = setTimeout(function () {
            sliderComponents.forEach(loadCss);
        }, 6000);
    }

    window.addEventListener("resize", resetAnimations);
    window.addEventListener("orientationchange", resetAnimations);
});
