define('SearchFormComponent',["SearchAutocompleteComponent"], function (SearchAutocompleteComponent) {
    SearchAutocompleteComponent.initAll();

    var SearchFormComponent = function (settings) {
        if (!settings || // There're no settings
            (typeof settings !== "object") || // Settings are not an object
            !(settings.form instanceof HTMLFormElement)) { // Settings don't have a 'form' prop defined
            throw new TypeError();
        }

        this.form = settings.form;
        this.autocomplete = settings.autocomplete || this.form.querySelector("[data-component=search-autocomplete]").searchAutocompleteComponent;
        this.searchTextField = settings.searchTextField || this.form.querySelector("[name=SearchText]");
        this.pageToHitFields = settings.pageToHitFields || this.form.querySelectorAll("[name=pageToHit]");
        this.latitudeField = settings.latitudeField || this.form.querySelector("[name=latitude]");
        this.longitudeField = settings.longitudeField || this.form.querySelector("[name=longitude]");
        this.locationTypeField = settings.locationTypeField || this.form.querySelector("[name=locationType]");
        this.itemIdField = settings.itemIdField || this.form.querySelector("[name=itemId]");
        this.marketIdField = settings.marketIdField || this.form.querySelector("[name=marketId]");
        this.submitButton = settings.submitButton || this.form.querySelector("[type=submit]");
      this.validationError = settings.validationError || this.form.querySelector("[data-field-validation-error]") || this.form.querySelector("#nhs_field_validation_error");
        this.useAutocompleteForSubmit = !!settings.useAutocompleteForSubmit || this.form.hasAttribute("data-component-useAutocompleteForSubmit");
        this.shouldAlwaysSubmitForm = settings.shouldAlwaysSubmitForm || false;
        this.useBaseUrl = settings.useBaseUrl || true;
        this.useBaseUrl = settings.useBaseUrl === undefined ? true : settings.useBaseUrl;


        if (this.submitButton) {
            this.submitButton.disabled = false;
        }

        //If there was an external specified submit button
        if (settings.submitButton) {
            settings.submitButton.addEventListener("click", this._formSubmitHandler.bind(this));
        }

        if (this.useAutocompleteForSubmit) {
            this.autocomplete.onSelect = this._formSubmitHandler.bind(this);
        }
        if (this.searchTextField) {
            this._setDataUserText();
        }

        this.form.addEventListener("submit", this._formSubmitHandler.bind(this));

        if(this.searchTextField){
            this.searchTextField.parentElement.addEventListener('focusout', (event) => {
                // close suggestions if the focus is moved to an outside element.
                if(event.relatedTarget){
                    this.autocomplete.hideSuggestions();
                }
            });
        }
    }

    SearchFormComponent.prototype._getPageToHit = function () {
        if (this.pageToHitFields.length === 1) {
            return this.pageToHitFields[0].value;
        }

        for (var i = 0; i < this.pageToHitFields.length; i++) {
            if (this.pageToHitFields[i].checked) {
                return this.pageToHitFields[i].value;
            }
        }
        return "";
    };

    SearchFormComponent.prototype._setPageToHitState = function (state) {
        for (var i = 0; i < this.pageToHitFields.length; i++) {
            this.pageToHitFields[i].disabled = state;
        }
    };

    SearchFormComponent.prototype._setUpForCurrentLocationSearch = function () {
        // Change the form action
        if (this.useBaseUrl) {
            this.form.action = document.baseURI + this._getPageToHit() + "/currentlocation";
        } else {
            this.form.action = "/" + (this._getPageToHit().indexOf("comm") > -1 ? "communities" : "homes") + "/currentlocation";
        }

        // These Fields must be included in the request
        this.latitudeField.disabled = false;
        this.longitudeField.disabled = false;

        // These fields should not be included in the request
        this.searchTextField.disabled = true;
        this._setPageToHitState(true);
        this.locationTypeField.disabled = true;
        this.itemIdField.disabled = true;
        this.marketIdField.disabled = true;
    };

    SearchFormComponent.prototype._setUpForLocationNameSearch = function () {
        // Change the form action
        if (this.form.action.indexOf("http://") !== 0 && this.form.action.indexOf("https://") !== 0) {
            this.form.action = "/submittypeaheadsearch";
        }

        // These fields should not be included in the request
        
        if(this.longitudeField){
            this.longitudeField.value = "";
            this.longitudeField.disabled = true;
        }

        if(this.latitudeField){
            this.latitudeField.value = "";
            this.latitudeField.disabled = true;
        }

        // These Fields must be included in the request            
        this.searchTextField.disabled = false;
        this._setPageToHitState(false);

        if(this.locationTypeField){
            this.locationTypeField.value = this.autocomplete.value.type;
            this.locationTypeField.disabled = false;
        }
        
        if(this.itemIdField){
            this.itemIdField.value = this.autocomplete.value.id;
            this.itemIdField.disabled = false;
        }
        
        if(this.marketIdField){
            this.marketIdField.value = this.autocomplete.value.marketId;
            this.marketIdField.disabled = false;
        }
    };

    SearchFormComponent.prototype._getCurrentLocation = function () {
        // User wants to use current location but the Browser needs to ask for permission in order to access the user coords
        navigator.geolocation.getCurrentPosition((function (position) {
            this.latitudeField.value = position.coords.latitude;
            this.longitudeField.value = position.coords.longitude;
            this._formSubmitHandler(); // Once the user has accepted the pop up and we have filled the fields as needed, submit the form, to start over.
        }).bind(this), (function (err) {
            this._showSearchSubmitError(); // If the user did not accept the pop up so we are gonna show the error
        }).bind(this), { maximumAge: 0 });
    };

    SearchFormComponent.prototype._showSearchSubmitError = function () {
        if (this.validationError) {
            this.validationError.style.display = "block";
            this.autocomplete.hideSuggestions();
        }
    };

    SearchFormComponent.prototype._clearSearchSubmitError = function () {
        if (this.validationError) {
            this.validationError.style.display = "none";
        }
    };

    SearchFormComponent.prototype._getRecentSearchValues = function () {
        this.autocomplete.update((function () {
            var index = -1;
            var clearSignsRegex = /,|\./gi;
            for (var i = 0; i < this.autocomplete.suggestions.length; i++) {
                if (this.autocomplete.suggestions[i].name.replace(clearSignsRegex, "").toLowerCase() === this.searchTextField.value.replace(clearSignsRegex, "").toLowerCase().trim()) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                this.autocomplete.selectSuggestion(index);
                this._formSubmitHandler();
            } else {
                this._showSearchSubmitError();
            }

        }).bind(this));
    };

    /**
     * @private
     * _getParamsEvent: function to get the parameter for events to track in element
     */
    SearchFormComponent.prototype._getParamsEvent = function () {
        const params = {};
        const elements = this.form.querySelectorAll('[data-ga4-param]');
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            params[element.dataset.ga4Param] = element.value;
        }
        return params;
    };

    SearchFormComponent.prototype._formSubmitHandler = function (event) {
        if (event) {
            event.preventDefault();
        }

        var inputText = this.form.querySelector('input[type="search"]');
        let textValue = inputText.value;
        if (inputText.dataset.userText != ''){
            textValue = inputText.dataset.userText;
        }
        const eventSegment = {
            event: inputText.id === "HeaderSearchText" || inputText.id === "SearchText" ? 'Search Term Selected' : "Header Search Term Selected",
            input_text: textValue,
            search_location: inputText.value,
            eventType: "segmentEvent"
        };

        const info = inputText.closest('[data-pandora-info-event]');
        const dataset = info && info.dataset;
        const eventPandora = dataset && {
            event: dataset.pandoraInfoEvent,
            pandoraCategory: dataset.pandoraInfoCategory,
            pandoraAction: dataset.pandoraInfoAction,
            pandoraLabel: inputText.value,
            eventType: "PandoraEvent"
        };

        const infoGa4 = inputText.closest('[data-ga4-info-event-name]');
        
        if(infoGa4 && this.searchTextField.value) {
            const paramsFromElements = this._getParamsEvent();
            const eventGa4 = {
                eventName: infoGa4.dataset.ga4InfoEventName,
                params: {
                    search_value: inputText.value,
                    click_target: infoGa4.dataset.ga4InfoEventClick_target
                    
                },
                eventType: "GA4Event"
            };
            Object.assign(eventGa4.params, paramsFromElements);

            document.body.dispatchEvent(new CustomEvent("trackOnDemandEvent", {
                detail: { data: eventGa4 }
            }));
        }

        document.body.dispatchEvent(new CustomEvent("trackOnDemandEvent", {
            detail: { data: eventSegment }
        }));
        
        eventPandora && document.body.dispatchEvent(new CustomEvent("trackOnDemandEvent", {
            detail: { data: eventPandora }
        }));
          

        if (this.autocomplete.isCurrentLocation && !this.latitudeField.value && !this.longitudeField.value) {
          this._getCurrentLocation();
          return;
        } else if (this.autocomplete.isCurrentLocation && this.latitudeField.value && this.longitudeField.value) {
          this._setUpForCurrentLocationSearch();
        } else if (this.shouldAlwaysSubmitForm && this.searchTextField.value) {
          this.form.submit();
        }else if (!!this.searchTextField.value && this.autocomplete && (!this.autocomplete.dependeciesRequested || this.autocomplete.value === null)) {
          this._getRecentSearchValues();
          return;
        } else if (this.autocomplete && this.autocomplete.value !== null) {
          this._setUpForLocationNameSearch();
        } else {
          this._showSearchSubmitError();
          return;
        }

        this._clearSearchSubmitError();
        this.form.submit();
    };

    SearchFormComponent.prototype._setDataUserText = function (event) {
        const searchTextInputs = document.querySelectorAll("[name=SearchText]");
        for (var i = 0; i < searchTextInputs.length; i += 1){
            searchTextInputs[i].oninput = function () {
                this.dataset.userText = this.value;
            };
        }
    };

    return SearchFormComponent;
});
